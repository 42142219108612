import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  BoxHeader,
  ControlBox,
  ButtonBox,
  Title,
  Table,
  TableHeader,
  TableRowHeader,
  TableRow,
  TableCell,
  StatusCircle,
  StyledLink,
  FilterBox,
  ActionFilterBox,
  BoxInputDescription,
  BoxInfoInput,
  StyledDatePicker,
  DatePickerWrapper,
  CalendarIcon,
  BoxDropDown,
  ButtonsBox,
  BoxInput,
} from '../ApplicationBusinessList/ApplicationBusinessList.style';
import { NewButton } from 'components/common/Button';
import { Pagination } from 'components/Pagination';
import { InputSearch } from 'components/common/Input/InputSearch';
import { InputNew } from 'components/common/Input/InputNew/InputNew';
import { Checkbox } from 'components/common/Checkbox';
import SelectBox from 'components/common/SelectBox/SelectBox';
import { default as IconTune } from 'assets/img/IconTune.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { default as CalendarIcons } from 'assets/img/CalendarIcon.svg';
import { useAppContext } from 'contexts/AppContext';
import {
  FiltersDocumentsList,
  PageChangeEventArgs,
  TableDataDocuments,
} from './DocumentsList.interface';
import { default as IconRedTimer } from 'assets/img/IconRedTimer.svg';
import { default as IconBlackTimer } from 'assets/img/IconBlackTimer.svg';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1`;

export const DocumentsList = () => {
  const navigate = useNavigate();
  const { userDataLight } = useAppContext();

  // State for pagination and search
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState<TableDataDocuments[]>([]);
  const [paginationInfo, setPaginationInfo] = useState({
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
  });
  const [endpoint, setEndpoint] = useState<string | null>(null);
  const [isFilterBoxOpen, setFilterBoxOpen] = useState(false);

  // State for filters
  const [counterpartyNameOrIinOrBin, setCounterpartyNameOrIinOrBin] = useState<string>('');
  const [counterpartyCeoName, setCounterpartyCeoName] = useState<string>('');
  const [counterpartyPhoneNumber, setCounterpartyPhoneNumber] = useState<string>('');
  const [investorRole, setInvestorRole] = useState<string>('');
  const [hasPayment, setHasPayment] = useState<boolean | null>(null);
  const [hasPaymentByDateStart, setHasPaymentByDateStart] = useState<Date | null>(null);
  const [hasPaymentByDateEnd, setHasPaymentByDateEnd] = useState<Date | null>(null);
  const [minContractSum, setMinContractSum] = useState<number | null>(null);
  const [maxContractSum, setMaxContractSum] = useState<number | null>(null);
  const [signDateStart, setSignDateStart] = useState<Date | null>(null);
  const [signDateEnd, setSignDateEnd] = useState<Date | null>(null);
  const [hasAvr, setHasAvr] = useState<boolean | null>(null);
  const [financingBeginDateStart, setFinancingBeginDateStart] = useState<Date | null>(null);
  const [financingBeginDateEnd, setFinancingBeginDateEnd] = useState<Date | null>(null);
  const [contractDurationStart, setContractDurationStart] = useState<number | null>(null);
  const [contractDurationEnd, setContractDurationEnd] = useState<number | null>(null);
  const [contractEndDateStart, setContractEndDateStart] = useState<Date | null>(null);
  const [contractEndDateEnd, setContractEndDateEnd] = useState<Date | null>(null);

  // State for sorting
  const [sortConfig, setSortConfig] = useState<{ field: string; direction: 'asc' | 'desc' | null }>(
    {
      field: '',
      direction: null,
    },
  );

  // Sorting handler
  const handleSort = (field: string) => {
    setSortConfig((prevSortConfig) => {
      if (prevSortConfig.field === field) {
        return {
          field,
          direction: prevSortConfig.direction === 'asc' ? 'desc' : 'asc',
        };
      }
      return { field, direction: 'asc' };
    });
  };

  // Data transformation
  const transformData = (data: any[]) => {
    return data.map((item) => ({
      id: item.id,
      contractNum: item.contractNum,
      contractSum: item.contractSum
        ? item.contractSum.toLocaleString('ru-KZ', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : 'null',
      product: item.productName,
      status: item.statusName,
      investorName: item.investorName,
      endDate: item.endDate,
      investorSigned: item.investorSigned,
      businessSigned: item.businessSigned,
      signingDtEnd: item.signingDtEnd,
      businessName: item.businessName,
    }));
  };

  // Fetch filters and sorting
  const getFilters = (): FiltersDocumentsList => {
    return {
      contractNum: searchValue || undefined,
      counterpartyNameOrIinOrBin: counterpartyNameOrIinOrBin || undefined,
      counterpartyCeoName: counterpartyCeoName || undefined,
      counterpartyPhoneNumber: counterpartyPhoneNumber || undefined,
      investorRole: investorRole || undefined,
      hasPayment: hasPayment !== null ? hasPayment : undefined,
      hasPaymentByDateStart: hasPaymentByDateStart?.toISOString().split('T')[0] || undefined,
      hasPaymentByDateEnd: hasPaymentByDateEnd?.toISOString().split('T')[0] || undefined,
      minContractSum: minContractSum || undefined,
      maxContractSum: maxContractSum || undefined,
      signDateStart: signDateStart?.toISOString().split('T')[0] || undefined,
      signDateEnd: signDateEnd?.toISOString().split('T')[0] || undefined,
      hasAvr: hasAvr !== null ? hasAvr : undefined,
      financingBeginDateStart: financingBeginDateStart?.toISOString().split('T')[0] || undefined,
      financingBeginDateEnd: financingBeginDateEnd?.toISOString().split('T')[0] || undefined,
      contractDurationStart: contractDurationStart || undefined,
      contractDurationEnd: contractDurationEnd || undefined,
      contractEndDateStart: contractEndDateStart?.toISOString().split('T')[0] || undefined,
      contractEndDateEnd: contractEndDateEnd?.toISOString().split('T')[0] || undefined,
    };
  };

  // Fetch data with filters and sorting using POST request
  const fetchData = useCallback(
    async (
      page = 0,
      filters: FiltersDocumentsList = {},
      sort: { field: string; direction: 'asc' | 'desc' | null } = sortConfig,
    ) => {
      if (!endpoint) return;

      try {
        const params = new URLSearchParams();
        params.append('page', page.toString());

        // Add sorting configuration
        if (sort.field && sort.direction) {
          params.append('sort', `${sort.field},${sort.direction}`);
        }

        // Use POST request instead of GET, sending filters and sortConfig in the request body
        const response = await axios.post(`${endpoint}?${params.toString()}`, filters, {
          withCredentials: true,
        });

        setTableData(transformData(response.data.content));
        setPaginationInfo({
          totalPages: response.data.totalPages,
          totalElements: response.data.totalElements,
          currentPage: response.data.number,
        });
      } catch (error) {
        console.error('Ошибка загрузки данных:', error);
      }
    },
    [endpoint, sortConfig],
  );

  // Set API endpoint based on user's role
  useEffect(() => {
    if (!userDataLight) return;

    const newEndpoint =
      userDataLight.organizationRole === 'INVESTOR'
        ? `${BASE_URL}/cabinet/investor/contract`
        : `${BASE_URL}/cabinet/creditor/contract`;
    setEndpoint(newEndpoint);
  }, [userDataLight]);

  // Load data when endpoint, pagination, or sorting changes
  useEffect(() => {
    fetchData(currentPage, getFilters(), sortConfig);
  }, [endpoint, currentPage, sortConfig, fetchData]);

  // Handle page change
  const handlePageChange = useCallback(
    ({ selected }: PageChangeEventArgs) => {
      setCurrentPage(selected);
      fetchData(selected, getFilters(), sortConfig);
    },
    [getFilters, sortConfig],
  );

  // Apply filters and sorting
  const applyFiltersAndSorting = () => {
    setCurrentPage(0); // Reset to page 0 when applying filters
    fetchData(0, getFilters(), sortConfig);
  };

  // Clear all filters
  const clearFilters = () => {
    setSearchValue('');
    setCounterpartyNameOrIinOrBin('');
    setCounterpartyCeoName('');
    setCounterpartyPhoneNumber('');
    setInvestorRole('');
    setHasPayment(null);
    setHasPaymentByDateStart(null);
    setHasPaymentByDateEnd(null);
    setMinContractSum(null);
    setMaxContractSum(null);
    setSignDateStart(null);
    setSignDateEnd(null);
    setHasAvr(null);
    setFinancingBeginDateStart(null);
    setFinancingBeginDateEnd(null);
    setContractDurationStart(null);
    setContractDurationEnd(null);
    setContractEndDateStart(null);
    setContractEndDateEnd(null);

    setSortConfig({ field: '', direction: null });
    setCurrentPage(0);
    fetchData(0, {}, { field: '', direction: null });
  };

  // Calculate remaining time for signing
  const calculateRemainingTime = (signingDtEnd: string) => {
    const endDate = new Date(signingDtEnd);
    const now = new Date();
    const timeDiff = endDate.getTime() - now.getTime();

    if (timeDiff <= 0) {
      return { text: 'Время истекло', expired: true };
    }

    const daysRemaining = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysRemaining >= 1) {
      return { text: `${daysRemaining} ${daysRemaining === 1 ? 'день' : 'д'}`, expired: false };
    }

    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60)) / (1000 * 60));

    return { text: `${hours}:${minutes}`, expired: false };
  };

  // Toggle filter box visibility
  const toggleFilterBox = () => {
    setFilterBoxOpen((prev) => !prev);
  };

  // Filtered data by contract number
  const filteredData = useMemo(() => {
    return tableData.filter((row) =>
      row.contractNum?.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [searchValue, tableData]);

  return (
    <Container>
      <Title>Договоры</Title>
      <BoxHeader>
        <ControlBox>
          <InputSearch
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder='Поиск договоров'
            name='searchQuery'
          />
          <FilterBox onClick={toggleFilterBox} isActive={isFilterBoxOpen}>
            <img src={IconTune} alt='Фильтр' />
          </FilterBox>
        </ControlBox>
      </BoxHeader>

      {isFilterBoxOpen && (
        <ActionFilterBox>
          <BoxInputDescription>
            <BoxInput>
              <p>Сумма договора (от/до)</p>
              <BoxInfoInput>
                <span>От</span>
                <InputNew
                  placeholder='0'
                  value={minContractSum !== null ? minContractSum.toString() : ''}
                  onChange={(e) =>
                    setMinContractSum(e.target.value ? parseFloat(e.target.value) : null)
                  }
                  maxWidth='100px'
                  type='number'
                />
                <span>До</span>
                <InputNew
                  placeholder='0'
                  value={maxContractSum !== null ? maxContractSum.toString() : ''}
                  onChange={(e) =>
                    setMaxContractSum(e.target.value ? parseFloat(e.target.value) : null)
                  }
                  maxWidth='100px'
                  type='number'
                />
              </BoxInfoInput>
            </BoxInput>
            <BoxInput>
              <p>Дата подписания договора</p>
              <BoxInfoInput>
                <span>С</span>
                <DatePickerWrapper>
                  <StyledDatePicker
                    selected={signDateStart}
                    dateFormat='dd/MM/yyyy'
                    onChange={(date: Date | null) => setSignDateStart(date)}
                    placeholderText='Выберите дату'
                  />
                  <CalendarIcon src={CalendarIcons} alt='Календарь' />
                </DatePickerWrapper>
                <span>По</span>
                <DatePickerWrapper>
                  <StyledDatePicker
                    dateFormat='dd/MM/yyyy'
                    selected={signDateEnd}
                    onChange={(date: Date | null) => setSignDateEnd(date)}
                    placeholderText='Выберите дату'
                  />
                  <CalendarIcon src={CalendarIcons} alt='Календарь' />
                </DatePickerWrapper>
              </BoxInfoInput>
            </BoxInput>
            <BoxInput>
              <p>Наличие сгенерированных АВР</p>
              <BoxInfoInput>
                <Checkbox
                  checked={hasAvr === true}
                  onChange={() => setHasAvr(hasAvr === true ? null : true)}
                />
                <span>Есть АВР</span>
              </BoxInfoInput>
              <BoxInfoInput>
                <Checkbox
                  checked={hasAvr === false}
                  onChange={() => setHasAvr(hasAvr === false ? null : false)}
                />
                <span>Нет АВР</span>
              </BoxInfoInput>
            </BoxInput>
          </BoxInputDescription>

          <BoxInputDescription>
            <BoxInput>
              <p>Дата начала финансирования</p>
              <BoxInfoInput>
                <span>С</span>
                <DatePickerWrapper>
                  <StyledDatePicker
                    selected={financingBeginDateStart}
                    dateFormat='dd/MM/yyyy'
                    onChange={(date: Date | null) => setFinancingBeginDateStart(date)}
                    placeholderText='Выберите дату'
                  />
                  <CalendarIcon src={CalendarIcons} alt='Календарь' />
                </DatePickerWrapper>
                <span>По</span>
                <DatePickerWrapper>
                  <StyledDatePicker
                    dateFormat='dd/MM/yyyy'
                    selected={financingBeginDateEnd}
                    onChange={(date: Date | null) => setFinancingBeginDateEnd(date)}
                    placeholderText='Выберите дату'
                  />
                  <CalendarIcon src={CalendarIcons} alt='Календарь' />
                </DatePickerWrapper>
              </BoxInfoInput>
            </BoxInput>
            <BoxInput>
              <p>Срок действия договора (в днях)</p>
              <BoxInfoInput>
                <span>От</span>
                <InputNew
                  placeholder='0'
                  value={contractDurationStart !== null ? contractDurationStart.toString() : ''}
                  onChange={(e) =>
                    setContractDurationStart(e.target.value ? parseInt(e.target.value) : null)
                  }
                  maxWidth='100px'
                  type='number'
                />
                <span>До</span>
                <InputNew
                  placeholder='0'
                  value={contractDurationEnd !== null ? contractDurationEnd.toString() : ''}
                  onChange={(e) =>
                    setContractDurationEnd(e.target.value ? parseInt(e.target.value) : null)
                  }
                  maxWidth='100px'
                  type='number'
                />
              </BoxInfoInput>
            </BoxInput>
            <BoxInput>
              <p>Дата завершения договора</p>
              <BoxInfoInput>
                <span>С</span>
                <DatePickerWrapper>
                  <StyledDatePicker
                    selected={contractEndDateStart}
                    dateFormat='dd/MM/yyyy'
                    onChange={(date: Date | null) => setContractEndDateStart(date)}
                    placeholderText='Выберите дату'
                  />
                  <CalendarIcon src={CalendarIcons} alt='Календарь' />
                </DatePickerWrapper>
                <span>По</span>
                <DatePickerWrapper>
                  <StyledDatePicker
                    dateFormat='dd/MM/yyyy'
                    selected={contractEndDateEnd}
                    onChange={(date: Date | null) => setContractEndDateEnd(date)}
                    placeholderText='Выберите дату'
                  />
                  <CalendarIcon src={CalendarIcons} alt='Календарь' />
                </DatePickerWrapper>
              </BoxInfoInput>
            </BoxInput>
          </BoxInputDescription>
          <BoxInputDescription>
            {/* <BoxInput>
              <p>Заемщик (наименование, БИН/ИИН)</p>
              <InputNew
                value={counterpartyNameOrIinOrBin}
                onChange={(e) => setCounterpartyNameOrIinOrBin(e.target.value)}
                placeholder='Введите наименование или ИИН/БИН'
              />
            </BoxInput> */}
            <BoxInput>
              <p>
                Первый руководитель{' '}
                {userDataLight?.organizationRole === 'INVESTOR' ? 'бизнеса' : 'инвестора'} (ИИН)
              </p>
              <InputNew
                value={counterpartyCeoName}
                onChange={(e) => setCounterpartyCeoName(e.target.value)}
                placeholder='Введите ИИН руководителя'
              />
            </BoxInput>
            {/* <BoxInput>
              <p>Телефон заемщика</p>
              <InputNew
                value={counterpartyPhoneNumber}
                onChange={(e) => setCounterpartyPhoneNumber(e.target.value)}
                placeholder='Введите телефон'
              />
            </BoxInput> */}
            <BoxInput>
              <SelectBox
                label={`Роль ${userDataLight?.organizationRole === 'INVESTOR' ? 'бизнеса' : 'инвестора'}`}
                options={[
                  { id: 'TOO', name: 'ТОО' },
                  { id: 'IP', name: 'ИП' },
                  { id: 'FL', name: 'ФЛ' },
                ]}
                selectedOptions={investorRole ? [investorRole] : []}
                onOptionSelect={(option) =>
                  setInvestorRole(investorRole === option.name ? '' : option.name)
                }
              />
            </BoxInput>
            <BoxInput>
              <p>Наличие платежей по договору</p>
              <BoxInfoInput>
                <Checkbox
                  checked={hasPayment === true}
                  onChange={() => setHasPayment(hasPayment === true ? null : true)}
                />
                <span>Есть платежи</span>
              </BoxInfoInput>
              <BoxInfoInput>
                <Checkbox
                  checked={hasPayment === false}
                  onChange={() => setHasPayment(hasPayment === false ? null : false)}
                />
                <span>Нет платежей</span>
              </BoxInfoInput>
              {hasPayment !== null && (
                <BoxInfoInput>
                  <span>С</span>
                  <DatePickerWrapper>
                    <StyledDatePicker
                      selected={hasPaymentByDateStart}
                      dateFormat='dd/MM/yyyy'
                      onChange={(date: Date | null) => setHasPaymentByDateStart(date)}
                      placeholderText='Выберите дату'
                    />
                    <CalendarIcon src={CalendarIcons} alt='Календарь' />
                  </DatePickerWrapper>
                  <span>По</span>
                  <DatePickerWrapper>
                    <StyledDatePicker
                      dateFormat='dd/MM/yyyy'
                      selected={hasPaymentByDateEnd}
                      onChange={(date: Date | null) => setHasPaymentByDateEnd(date)}
                      placeholderText='Выберите дату'
                    />
                    <CalendarIcon src={CalendarIcons} alt='Календарь' />
                  </DatePickerWrapper>
                </BoxInfoInput>
              )}
            </BoxInput>
          </BoxInputDescription>
          <BoxDropDown>{/* Дополнительные фильтры можно добавить здесь */}</BoxDropDown>

          <ButtonsBox>
            <NewButton text='Сбросить все фильтры' className='white small' onClick={clearFilters} />
            <NewButton
              text='Применить фильтры'
              className='small'
              onClick={applyFiltersAndSorting}
            />
          </ButtonsBox>
        </ActionFilterBox>
      )}

      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader
              isSorted={sortConfig.field === 'contractNum'}
              direction={sortConfig.field === 'contractNum' ? sortConfig.direction : null}
              onClick={() => handleSort('contractNum')}>
              Номер договора
            </TableHeader>
            <TableHeader>
              {userDataLight?.organizationRole === 'INVESTOR' ? 'Заемщик' : 'Инвестор'}
            </TableHeader>
            <TableHeader
              isSorted={sortConfig.field === 'contractSum'}
              direction={sortConfig.field === 'contractSum' ? sortConfig.direction : null}
              onClick={() => handleSort('contractSum')}>
              Сумма займа
            </TableHeader>
            <TableHeader>Продукт</TableHeader>
            <TableHeader>Статус</TableHeader>
            <TableHeader
              isSorted={sortConfig.field === 'signingDtEnd'}
              direction={sortConfig.field === 'signingDtEnd' ? sortConfig.direction : null}
              onClick={() => handleSort('signingDtEnd')}>
              Время подписания
            </TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {filteredData.map((row) => {
            const { text: remainingTime, expired } = calculateRemainingTime(row.signingDtEnd);

            return (
              <TableRow key={row.id} onClick={() => navigate(`/contracts/${row.id}`)}>
                <TableCell>
                  <StyledLink to={`/contracts/${row.id}`}>{row.contractNum}</StyledLink>
                </TableCell>
                <TableCell>
                  {userDataLight?.organizationRole === 'INVESTOR'
                    ? row.businessName
                    : row.investorName}
                </TableCell>
                <TableCell className='right'>{row.contractSum} ₸</TableCell>
                <TableCell>{row.product}</TableCell>
                <TableCell>
                  <StatusCircle $status={row.status} />
                  {row.status}
                </TableCell>
                <TableCell style={{ color: expired ? 'red' : 'inherit' }}>
                  <div>
                    <img src={expired ? IconRedTimer : IconBlackTimer} alt='Timer Icon' />
                    {remainingTime}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        pageCount={paginationInfo.totalPages}
        currentPage={paginationInfo.currentPage}
        onPageChange={handlePageChange}
      />
    </Container>
  );
};
