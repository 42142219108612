import styled, { css } from 'styled-components';
import colors from 'global_styling/colors';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';

type StatusCircleProps = {
  $status:
    | 'На рассмотрении у инвесторов'
    | 'Откланена'
    | 'На рассмотрение'
    | 'Просрочена'
    | 'Подписан'
    | 'На подписании'
    | 'Отменен заёмщиком'
    | 'Активный'
    | string;
};

interface TableHeaderProps {
  isSorted?: boolean;
  direction?: 'asc' | 'desc' | null;
}

const Container = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
`;

const BoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 16px;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
`;

const ControlBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const FilterBox = styled.div<{ isActive: boolean }>`
  display: flex;
  width: 46px;
  height: 44px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ isActive }) => (isActive ? '#BCBCBC' : 'var(--primary-300, #5784F7)')};
  border-radius: 8px;
  margin-left: 2px;
  cursor: pointer;
  &:hover {
    background: ${({ isActive }) => (isActive ? '#BCBCBC' : 'var(--primary-400, #0f62fe)')};
  }
`;

export const ActionFilterBox = styled.div`
  display: flex;
  width: 100%;
  padding: 36px;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(7, 17, 43, 0.07);
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-height: 46px;
`;

const Title = styled.h1`
  color: #292929;
  font-size: 28px;
  font-weight: 600;
  line-height: 106%;
  font-family: 'IBM Plex Sans', sans-serif;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0px 22.78125px 48.825px 0px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin-bottom: 30px;
  background: var(--Neutral-2, #fafafa);
`;

export const TableHeader = styled.th<TableHeaderProps>`
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  padding: 12px;
  min-height: 48px;
  text-align: left;
  background: var(--Neutral-2, #fafafa);
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #f2f2f2;
  }

  /* Резервируем место под стрелку */
  &::after {
    content: ${({ direction }) =>
      direction === 'asc'
        ? '"↑"'
        : direction === 'desc'
          ? '"↓"'
          : '""'}; /* Используем двойные кавычки */
    font-size: 12px;
    position: absolute;
    right: 5px; /* Стрелка сдвинута к правому краю */
    top: 50%;
    transform: translateY(-50%);
    color: ${({ isSorted }) =>
      isSorted
        ? 'var(--character-title-85, rgba(0, 0, 0, 0.85))'
        : 'transparent'}; /* Если сортировка активна, показываем стрелку */
  }
`;

const TableRowHeader = styled.tr`
  border: none;
  .fixStatus {
    min-width: 140px;
  }
`;

const TableRow = styled.tr<{ $isSelected?: boolean }>`
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  background: ${(props) => (props.$isSelected ? '#EDFEFF' : 'transparent')};
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }
  .right {
    text-align: right;
  }
`;

const TableCell = styled.td`
  padding: 12px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  max-width: 190px;
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  div {
    display: flex;
    align-items: center;
    gap: 10px;
    img {
    }
  }
  button {
    width: 100px;
  }
  .fixStatus {
    min-width: 120px;
  }
`;

const statusesAccept = ['Принято предложение инвестора', 'Подписан', 'Принят', 'Договор подписан'];
const statusesReject = [
  'Откланена',
  'Отменен заёмщиком',
  'Отменён инвестором',
  'Договор отклонен заемщиком',
  'Договор отклонен инвестором',
];
const statusesWait = [
  'На рассмотрение',
  'На рассмотрении у инвесторов',
  'Активный',
  'На подписании',
];

const StatusCircle = styled.span<StatusCircleProps>`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;

  background-color: ${({ $status }) => {
    if (statusesAccept.includes($status)) {
      return '#89E141';
    }
    if (statusesReject.includes($status)) {
      return '#FF3838';
    }
    if (statusesWait.includes($status)) {
      return '#FFD54E';
    }
    switch ($status) {
      case 'Просрочена':
        return ' #FF9C41';
      default:
        return 'gray';
    }
  }};
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ActionBox = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  gap: 10px;
  min-width: 200px;
  background: var(--White, #fff);
  top: 90%;
  right: 50px;
  z-index: 999;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 8px 16px 0px rgba(30, 34, 72, 0.16);
  li {
    color: var(--black-500-ink, #1e2248);
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    padding: 8px 16px;
    width: 100%;
    &:hover {
      background: var(--Primary-Highlight, #def5f6);
    }
  }
`;

const StyledLink = styled(Link)`
  color: #5784f7;
  text-decoration: none;
`;

interface InputProps {
  $hasError?: boolean;
  $hasContent?: boolean;
  $readOnly?: boolean;
}

export const Input = styled.input<InputProps>`
  width: 100%;
  padding: 0px 10px;
  border-radius: 0px 2px 2px 0px;
  border: none;
  border-bottom: 1px solid var(--neutral-300, #bcbcbc);
  color: var(--Control, #9aa4b0);
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  display: flex;
  &.max {
    height: 164px;
    vertical-align: top; /* Добавлено для выравнивания текста сверху */
    text-align: start; /* Выравнивание текста слева */
  }

  &.maxApplication {
    height: 164px;
    vertical-align: top;
    font-family: 'IBM Plex Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.16px;
    white-space: pre-wrap; /* Ensures that whitespace and line breaks are preserved */
  }

  /* Chrome, Safari, Edge, Opera Убираем стрелочки больше и меньше */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & {
    -moz-appearance: textfield;
  }

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      color: var(--neutral-800, #303030);
      border-bottom: 1px solid var(--neutral-300, #bcbcbc);
    `}

  ${({ $readOnly }) =>
    $readOnly &&
    css`
      color: var(--neutral-600, #686868);
      border-bottom: 1px solid var(--neutral-300, #bcbcbc);
    `}

  &:focus {
    outline: none;
    border-bottom: 1px solid var(--neutral-300, #5784f7);
    color: #292d32;
  }

  ${({ $hasError }) =>
    $hasError &&
    css`
      border: 1px solid ${colors.WARNING};
    `}
`;

export const BoxInputHeader = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 348px;
  gap: 8px;
`;

export const BoxInputDescription = styled.div`
  display: flex;
  gap: 24px;
  align-self: stretch;
`;

export const BoxInfoInput = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  span {
    color: var(--neutral-600, #686868);
    font-family: 'Jost';
    font-size: 14px;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
  }
`;

export const DatePickerWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 8px;
  border-radius: 0px 2px 2px 0px;
  border: none;
  background: var(--neutral-100, #f4f4f4);
  color: var(--neutral-400, #a0a0a0);
  border: 1px solid var(--Control, #f4f4f4);
  font-size: 14px;
  line-height: 129%;
  font-weight: 400;
  font-family: 'Jost';
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 4px;

  &::placeholder {
    color: var(--Control, #9aa4b0);
  }

  &:focus {
    outline: none;
    border: 1px solid var(--primary-300, #5784f7);
    color: #292d32;
  }

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      color: var(--neutral-800, #303030);
      border: 1px solid var(--neutral-800, #f4f4f4);
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      border-bottom: 1px solid ${colors.WARNING};
    `}
`;

export const CalendarIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
`;

export const BoxDropDown = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;
export const ButtonsBox = styled.div`
  display: flex;
  gap: 10px;
  margin-left: auto;
`;

export const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  p {
    color: var(--neutral-800, #303030);
    font-family: Jost;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
  }
`;

export {
  Container,
  Title,
  Table,
  TableRowHeader,
  TableRow,
  TableCell,
  BoxHeader,
  ControlBox,
  ButtonBox,
  StatusCircle,
  IconWrapper,
  ActionBox,
  StyledLink,
};
