import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FileLink = styled.div`
  align-items: center;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 6px;
  opacity: 0.8;
  background: #fff;
  align-self: stretch;
  text-decoration: none;
  color: #161616;
  font-family: 'Jost';
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.16px;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;

  div {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;

export const DowlandFile = styled.p`
  color: #0f62fe;
  font-family: 'Jost';
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  width: 50%;
`;

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 16px;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: 'Jost';
`;

export const StyledTableHead = styled.thead`
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: 'Jost';
  font-size: 14px;
  font-weight: 500;
  line-height: 14px; /* 107.143% */
  text-align: left;
  padding: 12px;
  .right {
    text-align: right;
  }
`;

export const StyledTableRow = styled.tr`
  border-bottom: 1px solid #e0e0e0;
  .right {
    text-align: right;
  }
`;

export const StyledTableCell = styled.td`
  padding: 12px 16px;
  color: #0f1419;
  font-family: 'Jost';
  font-size: 12px;
  line-height: 15px; /* 125% */
  background-color: #fff;
`;

export const TotalRow = styled(StyledTableRow)`
  font-weight: bold;
`;

export const MainInfoTitleBlue = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 8px 8px 0px 0px;
  color: #fff;
  font-family: 'Jost';
  font-size: 18px;
  font-weight: 600;
  line-height: 125%; /* 23.193px */
  cursor: pointer;
  background: var(--primary-300, #5784f7);
  padding: 24px;
  width: 100%;

  img {
    filter: invert(1); /* Makes the image white */
  }
`;

export const MainInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 8px 8px;
  background-color: #fff;
`;

export const ExpandableSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;
