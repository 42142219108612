import styled from 'styled-components';

interface TabProps {
  active: boolean;
}

interface TagRiskProps {
  status?: string;
}

const getBackgroundColor = (status: string | undefined): string => {
  return status === 'DRAFT' ? 'var(--neutral-500, #848484)' : 'var(--functional-positive, #29e584)';
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 18px;
  align-self: stretch;
  background: var(--neutral-100, #f4f4f4);
`;

export const BreadCrumbsBox = styled.div`
  display: flex;
  gap: 16px;
  text-align: center;
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  p {
    text-decoration: none;
    color: var(--primary-400, #0f62fe);
    a {
      text-decoration: none;
      color: #5f5f5f;
    }
  }
  span {
    cursor: pointer;
    color: #5f5f5f;
  }
`;

export const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  .leftContent {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  h2 {
    color: #0d0d0d;
    font-family: 'IBM Plex Sans';
    font-size: 36px;
    font-weight: 600;
    line-height: 106%;
    @media (max-width: 1340px) {
      font-size: 26px;
    }
  }
  a {
    text-decoration: none;
  }

  button {
    margin-left: auto;
    @media (max-width: 1340px) {
      margin-left: 20px;
    }
  }
`;

export const TagRisk = styled.div<TagRiskProps>`
  display: inline-flex;
  background: ${(props) => getBackgroundColor(props.status)};
  overflow: hidden;
  color: var(--Background-White, #fff);
  font-family: 'IBM Plex Sans';
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  border-radius: 500px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media (max-width: 1340px) {
    font-size: 14px;
  }
`;

export const TagTime = styled.div`
  display: inline-flex;
  border: 1px solid var(--functional-negative, #fa4d56);
  border-radius: 500px;
  background: var(--neutral-50, #fefefe);
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  color: var(--Error, #ff5353);
  gap: 8px;
  font-family: 'IBM Plex Sans';
  font-size: 12px;
  font-weight: 600;
  @media (max-width: 1340px) {
    font-size: 14px;
  }
`;

export const Timer = styled.p`
  width: 68px;
  text-align: center;
  @media (max-width: 1340px) {
    width: 58px;
  }
`;

export const SwitcherBox = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

export const Tab = styled.div<TabProps>`
  padding: 14px;
  cursor: pointer;
  color: ${(props) => (props.active ? '#5784F7' : '#686868')};
  border-bottom: ${(props) => (props.active ? '2px solid #5784F7' : 'none')};
  font-family: 'Jost';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 90% */
  letter-spacing: 0.16px;
  display: flex;
`;

export const SwitcherBoxMini = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`;

export const TabMini = styled.div<TabProps>`
  padding: 0px 0px 12px 0px;
  cursor: pointer;
  color: ${(props) => (props.active ? '#303030' : '#686868')};
  border-bottom: ${(props) => (props.active ? '2px solid #5784F7' : 'none')};
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 90% */
  letter-spacing: 0.16px;
  display: flex;
`;

export const ContainerContent = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;
  width: 100%;
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  width: 100%;
  max-width: 390px;
  @media (max-width: 1350px) {
    max-width: 300px;
  }
`;

export const InfoBoxRight = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--neutral-50, #fefefe);
`;

export const InfoBoxRightCard = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  p {
    color: var(--neutral-400, var(--neutral-400, #a0a0a0));
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Jost';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
  }
  span {
    color: var(--neutral-900, var(--neutral-900, #141414));
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: 'Jost';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 25px */
  }
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 8px;
  background-color: #a5baf0; /* The color of the filled section */
  overflow: hidden;
  margin-top: 12px;
`;

export const ProgressBarFiller = styled.div`
  height: 100%;
  transition: width 0.3s ease-in-out;
  background-color: #5784f7; /* Neutral background color */
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const ContainerMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  align-self: stretch;
`;

export const ExpandableSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MainInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
  border-radius: 0px 0px 8px 8px;
  background: var(--neutral-50, #fefefe);
`;

export const MainInfoTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 8px 8px 0px 0px;
  color: var(--neutral-800, #303030);
  font-family: 'Jost';
  font-size: 18px;
  font-weight: 600;
  line-height: 125%; /* 23.193px */
  cursor: pointer;
  background: var(--neutral-50, #fefefe);
  padding: 24px;
  width: 100%;
`;

export const ArrowIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: auto;
`;

export const MainInfoContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; /* Две равные колонки */
  gap: 12px; /* Расстояние между колонками */
`;

export const MainInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--neutral-800, #303030);
  font-family: 'IBM Plex Sans';
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`;

export const MainInfoItemText = styled.p`
  color: var(--neutral-600, #686868);
  font-size: 18px;
  font-weight: 600;
  line-height: 125%;
  margin-bottom: 4px;
`;

export const MainInfoContentEdit = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
