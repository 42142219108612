import React, { useState, useEffect } from 'react';
import axios from 'axios'; // axios for making API requests
import IconDocuments from 'assets/img/IconDocuments.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import {
  ArrowIcon,
  MainInfoTitle,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import { FileItem } from 'components/ApplicationInvestor/ApplicationDocumentsList/ApplicationDocumentsList.style';
import {
  Container,
  MainInfoTitleBlue,
  StyledTable,
  StyledTableCell,
  StyledTableHead,
  StyledTableRow,
  TableContainer,
  MainInfoBox,
  FileLink,
  DowlandFile,
  ExpandableSection,
} from './BankStatement.style';
import { useFileDownload } from 'utils/hooks';

interface BankStatementProps {
  id: any;
}

interface BankStatementData {
  name: string;
  id: number;
  period: string;
}

interface MonthData {
  year: number;
  month: number | null;
  credit: string;
  debet: string;
}

interface CreditData {
  year: number | null;
  month: number | null;
  total: string;
  knp: string;
}

interface DebetData {
  year: number | null;
  month: number | null;
  total: string;
  knp: string;
}

interface BankStatementDetail {
  general: {
    biin: string;
    income: string;
    period: string;
    uid: string;
    outcome: string;
    filename: string;
    iik: string;
  };
  knp: { credit: string | null; debet: string | null; knp: string }[];
  month: MonthData[];
  counterparty: { counterparty: string; credit: string | null; debet: string | null }[];
  credit: CreditData[];
  debet: DebetData[];
}

const monthNames = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export const BankStatement: React.FC<BankStatementProps> = ({ id }) => {
  const [bankStatements, setBankStatements] = useState<BankStatementData[]>([]);
  const [expandedSections, setExpandedSections] = useState<{ [key: number]: boolean }>({});
  const [bankStatementDetails, setBankStatementDetails] = useState<{
    [key: number]: BankStatementDetail | null;
  }>({});
  const [expandedReports, setExpandedReports] = useState<{ [key: string]: boolean }>({});
  const [expandedYears, setExpandedYears] = useState<{ [year: number]: boolean }>({});
  const [expandedKNPs, setExpandedKNPs] = useState<{ [knp: string]: boolean }>({});
  const [expandedKNPDebets, setExpandedKNPDebets] = useState<{ [knp: string]: boolean }>({});

  const { downloadFile } = useFileDownload();

  useEffect(() => {
    // Fetching the list of bank statements
    const fetchBankStatements = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/v1/cabinet/investor/request/creditor/report/list/${id}`,
          { withCredentials: true },
        );
        setBankStatements(response.data);
      } catch (error) {
        console.error('Error fetching bank statements:', error);
      }
    };

    fetchBankStatements();
  }, [id]);

  const toggleSection = async (index: number, statementId: number) => {
    setExpandedSections((prev) => ({ ...prev, [index]: !prev[index] }));

    // Fetch details only if the section is being expanded for the first time
    if (!bankStatementDetails[statementId]) {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/v1/cabinet/investor/request/creditor/report/${statementId}`,
          { withCredentials: true },
        );
        setBankStatementDetails((prev) => ({ ...prev, [statementId]: response.data }));
      } catch (error) {
        console.error('Error fetching bank statement details:', error);
      }
    }
  };

  const toggleReport = (reportKey: string) => {
    setExpandedReports((prev) => ({ ...prev, [reportKey]: !prev[reportKey] }));
  };

  const toggleYear = (year: number) => {
    setExpandedYears((prev) => ({ ...prev, [year]: !prev[year] }));
  };

  const groupMonthsByYear = (months: MonthData[]) => {
    return months.reduce((acc: Record<number, MonthData[]>, month) => {
      const { year } = month;
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(month);
      return acc;
    }, {});
  };

  const toggleKNP = (knp: string) => {
    setExpandedKNPs((prev) => ({ ...prev, [knp]: !prev[knp] }));
  };

  const toggleKNPDebet = (knp: string) => {
    setExpandedKNPs((prev) => ({ ...prev, [knp]: !prev[knp] }));
  };

  const groupCreditByKNP = (credits: CreditData[]) => {
    return credits.reduce((acc: Record<string, CreditData[]>, credit) => {
      const { knp } = credit;
      if (!acc[knp]) {
        acc[knp] = [];
      }
      acc[knp].push(credit);
      return acc;
    }, {});
  };

  const groupDebetByKNP = (debets: DebetData[]) => {
    return debets.reduce((acc: Record<string, CreditData[]>, debet) => {
      const { knp } = debet;
      if (!acc[knp]) {
        acc[knp] = [];
      }
      acc[knp].push(debet);
      return acc;
    }, {});
  };

  return (
    <Container>
      {bankStatements.length > 0 ? (
        bankStatements.map((statement, index) => (
          <div key={statement.id}>
            <ExpandableSection>
              <MainInfoTitleBlue onClick={() => toggleSection(index, statement.id)}>
                <img src={IconDocuments} alt='icon' />
                {`Банковская выписка ${index + 1}`} ({statement.period})
                <ArrowIcon src={expandedSections[index] ? IconArrowDown : IconArrow} alt='arrow' />
              </MainInfoTitleBlue>
              {expandedSections[index] && bankStatementDetails[statement.id] && (
                <>
                  {/* General Information */}
                  <MainInfoBox>
                    <MainInfoTitle onClick={() => toggleReport(`general-${statement.id}`)}>
                      <img src={IconDocuments} alt='icon' />
                      Банковская выписка
                      <ArrowIcon
                        src={expandedReports[`general-${statement.id}`] ? IconArrowDown : IconArrow}
                        alt='arrow'
                      />
                    </MainInfoTitle>

                    {expandedReports[`general-${statement.id}`] &&
                      bankStatementDetails[statement.id]?.general.uid && (
                        <FileLink
                          key={bankStatementDetails[statement.id]?.general.uid}
                          onClick={() =>
                            downloadFile(bankStatementDetails[statement.id]?.general.uid as string)
                          } // Type assertion
                        >
                          <div>
                            <img src={IconDocuments} alt='download icon' />
                            {bankStatementDetails[statement.id]?.general.filename}
                          </div>
                          <DowlandFile>Скачать</DowlandFile>
                        </FileLink>
                      )}
                  </MainInfoBox>
                  {bankStatementDetails[statement.id] &&
                    Array.isArray(bankStatementDetails[statement.id]?.knp) &&
                    (bankStatementDetails[statement.id]?.knp?.length ?? 0) > 0 && (
                      <MainInfoBox>
                        <MainInfoTitle onClick={() => toggleReport(`knp-${statement.id}`)}>
                          <img src={IconDocuments} alt='download icon' />
                          Поступления и выбытия по КНП
                          <ArrowIcon
                            src={expandedReports[`knp-${statement.id}`] ? IconArrowDown : IconArrow}
                            alt='arrow'
                          />
                        </MainInfoTitle>
                        {expandedReports[`knp-${statement.id}`] && (
                          <TableContainer>
                            <StyledTable>
                              <StyledTableHead>
                                <StyledTableRow>
                                  <StyledTableCell>Код назначения платежа (КНП)</StyledTableCell>
                                  <StyledTableCell>Сумма поступлений</StyledTableCell>
                                  <StyledTableCell>Сумма выбытий</StyledTableCell>
                                </StyledTableRow>
                              </StyledTableHead>
                              <tbody>
                                {bankStatementDetails[statement.id]?.knp.map((knp, knpIndex) => (
                                  <StyledTableRow key={knpIndex}>
                                    <StyledTableCell>{knp.knp}</StyledTableCell>
                                    <StyledTableCell className='right'>
                                      {knp.debet
                                        ? `${parseFloat(knp.debet).toLocaleString('ru-RU')}`
                                        : '-'}
                                    </StyledTableCell>
                                    <StyledTableCell className='right'>
                                      {knp.credit
                                        ? `${parseFloat(knp.credit).toLocaleString('ru-RU')}`
                                        : '-'}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </tbody>
                            </StyledTable>
                          </TableContainer>
                        )}
                      </MainInfoBox>
                    )}

                  <MainInfoBox>
                    <MainInfoTitle onClick={() => toggleYear(statement.id)}>
                      <img src={IconDocuments} alt='icon' />
                      Динамика поступлений и выбытий по месяцам
                      <ArrowIcon
                        src={expandedYears[statement.id] ? IconArrowDown : IconArrow}
                        alt='arrow'
                      />
                    </MainInfoTitle>

                    {expandedYears[statement.id] && (
                      <TableContainer>
                        <StyledTable>
                          <StyledTableHead>
                            <StyledTableRow>
                              <StyledTableCell>Месяц</StyledTableCell>
                              <StyledTableCell>Поступления</StyledTableCell>
                              <StyledTableCell>Выбытия</StyledTableCell>
                            </StyledTableRow>
                          </StyledTableHead>
                          <tbody>
                            {/* Group the months by year */}
                            {Object.entries(
                              groupMonthsByYear(bankStatementDetails[statement.id]?.month || []),
                            ).map(([year, months]) => (
                              <React.Fragment key={year}>
                                {/* Year Row */}
                                <StyledTableRow
                                  onClick={() => toggleYear(Number(year))}
                                  style={{ cursor: 'pointer', backgroundColor: '#f1f3f4' }}>
                                  <StyledTableCell colSpan={1}>
                                    {year}
                                    <ArrowIcon
                                      src={expandedYears[Number(year)] ? IconArrowDown : IconArrow}
                                      alt='arrow'
                                      style={{ float: 'right' }}
                                    />
                                  </StyledTableCell>

                                  {/* "Итого" Cell */}
                                  <StyledTableCell className='right'>
                                    {/* Calculate yearly total debit */}
                                    {months
                                      .filter((month) => month.month !== null) // Only include months with valid data
                                      .reduce(
                                        (total, month) => total + parseFloat(month.debet || '0'),
                                        0,
                                      )
                                      .toLocaleString('ru-RU')}{' '}
                                    KZT
                                  </StyledTableCell>
                                  <StyledTableCell className='right'>
                                    {/* Calculate yearly total credit */}
                                    {months
                                      .filter((month) => month.month !== null) // Only include months with valid data
                                      .reduce(
                                        (total, month) => total + parseFloat(month.credit || '0'),
                                        0,
                                      )
                                      .toLocaleString('ru-RU')}{' '}
                                    KZT
                                  </StyledTableCell>
                                </StyledTableRow>

                                {/* If the year is expanded, show the months */}
                                {expandedYears[Number(year)] &&
                                  months
                                    .filter((monthData) => monthData.month !== null) // Only display months with valid data
                                    .map((monthData, monthIndex) => (
                                      <StyledTableRow key={monthIndex}>
                                        <StyledTableCell>
                                          {monthData.month !== null && monthData.month !== undefined
                                            ? monthNames[monthData.month - 1]
                                            : 'Месяц не указан'}
                                        </StyledTableCell>
                                        <StyledTableCell className='right'>
                                          {monthData.debet
                                            ? `${parseFloat(monthData.debet).toLocaleString('ru-RU')} KZT`
                                            : '-'}
                                        </StyledTableCell>
                                        <StyledTableCell className='right'>
                                          {monthData.credit
                                            ? `${parseFloat(monthData.credit).toLocaleString('ru-RU')} KZT`
                                            : '-'}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </StyledTable>
                      </TableContainer>
                    )}
                  </MainInfoBox>

                  <MainInfoBox>
                    <MainInfoTitle onClick={() => toggleReport(`counterparty-${statement.id}`)}>
                      <img src={IconDocuments} alt='download icon' />
                      Контрагенты
                      <ArrowIcon
                        src={
                          expandedReports[`counterparty-${statement.id}`]
                            ? IconArrowDown
                            : IconArrow
                        }
                        alt='arrow'
                      />
                    </MainInfoTitle>
                    {expandedReports[`counterparty-${statement.id}`] && (
                      <TableContainer>
                        <StyledTable>
                          <StyledTableHead>
                            <StyledTableRow>
                              <StyledTableCell>Контрагент</StyledTableCell>
                              <StyledTableCell>Сумма поступлений</StyledTableCell>
                              <StyledTableCell>Сумма выбытий</StyledTableCell>
                            </StyledTableRow>
                          </StyledTableHead>
                          <tbody>
                            {bankStatementDetails[statement.id]?.counterparty.map(
                              (counterpart, knpIndex) => (
                                <StyledTableRow key={knpIndex}>
                                  <StyledTableCell>{counterpart.counterparty}</StyledTableCell>
                                  <StyledTableCell className='right'>
                                    {counterpart.debet
                                      ? `${parseFloat(counterpart.debet).toLocaleString('ru-RU')} KZT`
                                      : '-'}
                                  </StyledTableCell>
                                  <StyledTableCell className='right'>
                                    {counterpart.credit
                                      ? `${parseFloat(counterpart.credit).toLocaleString('ru-RU')} KZT`
                                      : '-'}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ),
                            )}
                          </tbody>
                        </StyledTable>
                      </TableContainer>
                    )}
                  </MainInfoBox>
                  <MainInfoBox>
                    <MainInfoTitle onClick={() => toggleReport(`credit-${statement.id}`)}>
                      <img src={IconDocuments} alt='icon' />
                      Кредит отчет по КНП
                      <ArrowIcon
                        src={expandedReports[`credit-${statement.id}`] ? IconArrowDown : IconArrow}
                        alt='arrow'
                      />
                    </MainInfoTitle>
                    {expandedReports[`credit-${statement.id}`] && (
                      <TableContainer>
                        <StyledTable>
                          <StyledTableHead>
                            <StyledTableRow>
                              <StyledTableCell>КНП</StyledTableCell>
                              <StyledTableCell>Месяц</StyledTableCell>
                              <StyledTableCell>Сумма Кредита</StyledTableCell>
                            </StyledTableRow>
                          </StyledTableHead>
                          <tbody>
                            {Object.entries(
                              groupCreditByKNP(bankStatementDetails[statement.id]?.credit || []),
                            ).map(([knp, credits]) => (
                              <React.Fragment key={knp}>
                                {/* KNP Row */}
                                <StyledTableRow
                                  onClick={() => toggleKNP(knp)}
                                  style={{ cursor: 'pointer', backgroundColor: '#f1f3f4' }}>
                                  <StyledTableCell colSpan={1}>
                                    {knp}
                                    <ArrowIcon
                                      src={expandedKNPs[knp] ? IconArrowDown : IconArrow}
                                      alt='arrow'
                                      style={{ float: 'right' }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell></StyledTableCell>

                                  <StyledTableCell className='right'>
                                    {/* Calculate total for this KNP */}
                                    {credits
                                      .filter((credit) => credit.month !== null) // Only include months with valid data

                                      .reduce(
                                        (total, credit) => total + parseFloat(credit.total || '0'),
                                        0,
                                      )
                                      .toLocaleString('ru-RU')}{' '}
                                    KZT
                                  </StyledTableCell>
                                </StyledTableRow>

                                {/* If the KNP is expanded, show the months */}
                                {expandedKNPs[knp] &&
                                  credits
                                    .filter((creditData) => creditData.month !== null) // Only display months with valid data
                                    .map((creditData, creditIndex) => (
                                      <StyledTableRow key={creditIndex}>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>
                                          {creditData.month !== null &&
                                          creditData.month !== undefined
                                            ? monthNames[creditData.month - 1]
                                            : 'Месяц не указан'}
                                        </StyledTableCell>
                                        <StyledTableCell className='right'>
                                          {creditData.total
                                            ? `${parseFloat(creditData.total).toLocaleString(
                                                'ru-RU',
                                              )} KZT`
                                            : '-'}
                                        </StyledTableCell>
                                        {/* <StyledTableCell>{creditData.knp || '-'}</StyledTableCell> */}
                                      </StyledTableRow>
                                    ))}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </StyledTable>
                      </TableContainer>
                    )}
                  </MainInfoBox>
                  <MainInfoBox>
                    <MainInfoTitle onClick={() => toggleReport(`debet-${statement.id}`)}>
                      <img src={IconDocuments} alt='icon' />
                      Дебет отчет по КНП
                      <ArrowIcon
                        src={expandedReports[`debet-${statement.id}`] ? IconArrowDown : IconArrow}
                        alt='arrow'
                      />
                    </MainInfoTitle>
                    {expandedReports[`debet-${statement.id}`] && (
                      <TableContainer>
                        <StyledTable>
                          <StyledTableHead>
                            <StyledTableRow>
                              <StyledTableCell>КНП</StyledTableCell>
                              <StyledTableCell>Месяц</StyledTableCell>
                              <StyledTableCell>Сумма Дебета</StyledTableCell>
                            </StyledTableRow>
                          </StyledTableHead>
                          <tbody>
                            {Object.entries(
                              groupDebetByKNP(bankStatementDetails[statement.id]?.debet || []),
                            ).map(([knp, debets]) => (
                              <React.Fragment key={knp}>
                                {/* KNP Row */}
                                <StyledTableRow
                                  onClick={() => toggleKNP(knp)}
                                  style={{ cursor: 'pointer', backgroundColor: '#f1f3f4' }}>
                                  <StyledTableCell colSpan={1}>
                                    {knp}
                                    <ArrowIcon
                                      src={expandedKNPs[knp] ? IconArrowDown : IconArrow}
                                      alt='arrow'
                                      style={{ float: 'right' }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell></StyledTableCell>

                                  <StyledTableCell className='right'>
                                    {/* Calculate total for this KNP */}
                                    {debets
                                      .filter((debet) => debet.month !== null) // Only include months with valid data

                                      .reduce(
                                        (total, debet) => total + parseFloat(debet.total || '0'),
                                        0,
                                      )
                                      .toLocaleString('ru-RU')}{' '}
                                    KZT
                                  </StyledTableCell>
                                </StyledTableRow>

                                {/* If the KNP is expanded, show the months */}
                                {expandedKNPs[knp] &&
                                  debets
                                    .filter((debetData) => debetData.month !== null) // Only display months with valid data
                                    .map((debetData, debetIndex) => (
                                      <StyledTableRow key={debetIndex}>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>
                                          {debetData.month !== null && debetData.month !== undefined
                                            ? monthNames[debetData.month - 1]
                                            : 'Месяц не указан'}
                                        </StyledTableCell>
                                        <StyledTableCell className='right'>
                                          {debetData.total
                                            ? `${parseFloat(debetData.total).toLocaleString(
                                                'ru-RU',
                                              )} KZT`
                                            : '-'}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    ))}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </StyledTable>
                      </TableContainer>
                    )}
                  </MainInfoBox>
                </>
              )}
            </ExpandableSection>
          </div>
        ))
      ) : (
        <div>Нет банковских выписок</div>
      )}
    </Container>
  );
};
