import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  ApplicationDocumentsListProps,
  ApplicationFile,
} from './ApplicationDocumentsList.interface';
import IconDocuments from 'assets/img/IconDocuments.svg';
import IconArrow from 'assets/img/IconArrow.svg';
import IconArrowDown from 'assets/img/IconArrowDown.svg';
import { default as GroupIconDecline } from 'assets/img/GroupIconDecline.svg';
import { default as GroupIconEdit } from 'assets/img/GroupIconEdit.svg';

import {
  Container,
  MainInfoBox,
  FileItem,
  FileLink,
  DeleteIcon,
  ButtonBox,
  EditIcon,
} from './ApplicationDocumentsList.style';
import {
  ExpandableSection,
  MainInfoTitle,
  ArrowIcon,
  MainInfoItemText,
} from 'components/ApplicationInvestor/ApplicationInvestorNew.style';
import { DocLinkBox } from '../RequestDetailsNew.style';
import { WarningModal } from 'components/common/WarningModal';
import { useFileDownload } from 'utils/hooks';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/api/v1/`;
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

const getStatusColor = (status: string) => {
  switch (status) {
    case 'Успешно':
      return 'green';
    case 'Ошибка в формате файла':
      return 'red';
    case 'Отправлен в обработку':
    case 'Принят в обработку':
      return 'orange';
    case 'Файл уже существует':
      return 'red';
    default:
      return 'black'; // Default color for unknown statuses
  }
};

export const ApplicationDocumentsList: React.FC<ApplicationDocumentsListProps> = ({
  files,
  isEditing,
  onFilesChange,
  setIsUploading,
  isUploading,
  resetFiles,
  onDeleteFile,
  docLink,
  requestData,
  selectedAccount,
  selectedContract,
  selectedIndustry,
  selectedGoal,
}) => {
  const [isMainInfoExpanded, setIsMainInfoExpanded] = useState(true);
  const [isMainInfoExpandedBank, setIsMainInfoExpandedBank] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState<ApplicationFile[]>(files ?? []);
  const [warningMessage, setWarningMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [externalLink, setExternalLink] = useState<string | null>(null);
  const { downloadFile } = useFileDownload();
  const fileInputRef = useRef<HTMLInputElement | null>(null); // For handling file input
  const [fileCategory, setFileCategory] = useState<string | null>(null); // For tracking the file category

  useEffect(() => {
    setUploadedFiles(files);
  }, [files]);

  const saveRequestWithFiles = async (updatedFiles: ApplicationFile[]) => {
    try {
      const dataToSave = {
        id: requestData.id,
        account: String(selectedAccount?.id),
        product: requestData?.product,
        requestedAmount: String(requestData?.requestedAmount),
        requestPeriod: String(requestData?.requestPeriod),
        requestRate: String(requestData?.requestRate),
        requestedMinAmount: String(requestData?.requestedMinAmount),
        loanPurposeProject: String(requestData?.loanPurposeProject),
        loanPurposeProjectDesc: String(requestData?.loanPurposeProjectDesc),
        logoFileId: String(requestData?.logoFileId),
        docLink: String(requestData?.docLink),
        files: updatedFiles.map((file) => ({
          file: file.file,
          code: file.code,
          title: file.title || '',
        })),
        contracts: selectedContract?.id
          ? [
              {
                id: selectedContract.id,
                num: selectedContract.contractNumberSys || selectedContract.num,
                type: 'egz',
              },
            ]
          : [],
        industry: {
          id: selectedIndustry?.id,
        },
        funding_goals: selectedGoal?.id
          ? {
              id: selectedGoal.id,
            }
          : null,
      };

      await axios.post(`${BASE_URL}/cabinet/creditor/request/edit`, dataToSave, {
        withCredentials: true,
      });
    } catch (error) {
      console.error('Error saving request with files:', error);
    }
  };

  // Handling file delete
  const handleDeleteFile = async (fileId: string) => {
    const updatedFiles = uploadedFiles.filter((file) => file.file !== fileId);
    setUploadedFiles(updatedFiles);
    onFilesChange(updatedFiles);

    // Save the updated request with the new files
    await saveRequestWithFiles(updatedFiles);
  };

  // Handling file edit and replacement
  const handleEditFile = async (fileId: string, code: string | null) => {
    // Remove the file being replaced from the list
    const updatedFiles = uploadedFiles.filter((file) => file.file !== fileId);
    setUploadedFiles(updatedFiles);
    onFilesChange(updatedFiles);

    // Trigger the file input for replacement
    setFileCategory(code); // Set the category for the file being replaced
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Open file selection dialog
    }
  };

  const handleFileUploadWithNewFile = async (file: File, code: string | null) => {
    if (file.size > MAX_FILE_SIZE) {
      setWarningMessage('File size exceeds the 5MB limit.');
      return;
    }

    setWarningMessage('');
    const formData = new FormData();
    formData.append('file', file);

    try {
      setIsUploading(true);
      const response = await axios.post(`${BASE_URL}ui/file`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const newFile: ApplicationFile = { file: response.data.uid, code, title: file.name };
      const updatedFiles = [...uploadedFiles, newFile]; // Append the new file to the existing ones
      setUploadedFiles(updatedFiles);
      onFilesChange(updatedFiles);

      // Save the updated request with the new files
      await saveRequestWithFiles(updatedFiles);
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsUploading(false);
    }
  };

  // Triggering the file input for uploading a new file
  const handleUploadNewFile = (code: string | null) => {
    setFileCategory(code); // Set the category (BANK_STATEMENT or null) before uploading
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // Handle file input change event
  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      await handleFileUploadWithNewFile(file, fileCategory); // Use the selected file category
    }
  };

  useEffect(() => {
    if (resetFiles) {
      setUploadedFiles(files);
    }
  }, [resetFiles, files]);

  const handleDocLinkClick = () => {
    setExternalLink(docLink);
    setIsModalOpen(true);
  };

  const handleModalConfirm = () => {
    if (externalLink) {
      window.open(externalLink, '_blank');
    }
    setIsModalOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setExternalLink(null);
  };

  return (
    <Container>
      <input
        type='file'
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileInputChange} // Trigger file upload when a file is selected
        accept={fileCategory === 'BANK_STATEMENT' ? 'application/pdf' : '*'}
      />

      {/* General Documents Section */}
      {/* General Documents Section */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsMainInfoExpanded(!isMainInfoExpanded)}>
          <img src={IconDocuments} alt='icon' />
          Другие документы
          <ArrowIcon src={isMainInfoExpanded ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isMainInfoExpanded && (
          <MainInfoBox>
            {docLink && docLink !== '' && (
              <DocLinkBox>
                <MainInfoItemText>Ссылка на гугл папку</MainInfoItemText>
                <a onClick={handleDocLinkClick} style={{ cursor: 'pointer' }}>
                  {docLink}
                </a>
              </DocLinkBox>
            )}
            {uploadedFiles.length > 0 ? (
              uploadedFiles
                .filter((file) => file.code === null) // General documents
                .map((file) => (
                  <FileItem key={file.file}>
                    <FileLink onClick={() => downloadFile(file.file)}>
                      <img src={IconDocuments} alt='download icon' />
                      {file.title}
                    </FileLink>
                    {requestData.status === 'DRAFT' && (
                      <ButtonBox>
                        <EditIcon onClick={() => handleEditFile(file.file, null)}>
                          <img src={GroupIconEdit} alt='edit icon' />
                        </EditIcon>
                        <DeleteIcon onClick={() => handleDeleteFile(file.file)}>
                          <img src={GroupIconDecline} alt='delete icon' />
                        </DeleteIcon>
                      </ButtonBox>
                    )}
                  </FileItem>
                ))
            ) : (
              <div>Документы отсутствуют</div>
            )}
            {requestData.status === 'DRAFT' && (
              <span style={{ cursor: 'pointer' }} onClick={() => handleUploadNewFile(null)}>
                + Загрузить новый файл
              </span>
            )}
          </MainInfoBox>
        )}
      </ExpandableSection>

      {/* Bank Statement Documents Section */}
      <ExpandableSection>
        <MainInfoTitle onClick={() => setIsMainInfoExpandedBank(!isMainInfoExpandedBank)}>
          <img src={IconDocuments} alt='icon' />
          Документы банковской выписки
          <ArrowIcon src={isMainInfoExpandedBank ? IconArrowDown : IconArrow} alt='arrow' />
        </MainInfoTitle>
        {isMainInfoExpandedBank && (
          <MainInfoBox>
            {uploadedFiles
              .filter((file) => file.code === 'BANK_STATEMENT')
              .map((file) => (
                <FileItem key={file.file}>
                  <FileLink onClick={() => downloadFile(file.file)}>
                    <img src={IconDocuments} alt='download icon' />
                    {file.title}
                  </FileLink>

                  {/* Only show status if the request is in DRAFT */}
                  {requestData.status === 'DRAFT' && file.status && (
                    <span style={{ color: getStatusColor(file.status.name.text_ru) }}>
                      {file.status.name.text_ru}
                    </span>
                  )}

                  {requestData.status === 'DRAFT' && (
                    <ButtonBox>
                      <EditIcon onClick={() => handleEditFile(file.file, 'BANK_STATEMENT')}>
                        <img src={GroupIconEdit} alt='edit icon' />
                      </EditIcon>
                      <DeleteIcon onClick={() => handleDeleteFile(file.file)}>
                        <img src={GroupIconDecline} alt='delete icon' />
                      </DeleteIcon>
                    </ButtonBox>
                  )}
                </FileItem>
              ))}

            {requestData.status === 'DRAFT' && (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => handleUploadNewFile('BANK_STATEMENT')}>
                + Загрузить новый файл
              </span>
            )}

            {uploadedFiles.filter((file) => file.code === 'BANK_STATEMENT').length === 0 && (
              <div>Документы отсутствуют</div>
            )}
          </MainInfoBox>
        )}
      </ExpandableSection>

      {isModalOpen && (
        <WarningModal
          headerWarning='Предупреждение'
          textWarning='Вы переходите на внешнюю ссылку'
          buttonClose='Согласен'
          onClick={handleModalConfirm}
          onClose={handleModalClose}
        />
      )}
    </Container>
  );
};
