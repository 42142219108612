import styled from 'styled-components';

const Tag = styled.div`
  color: var(--Tag-Cyan-text, #fefefe);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.32px;
  border-radius: 24px;
  background: var(--Tag-Cyan-background, #007ea6);
  &.unimoney {
    color: var(--primary-50, var(--primary-50, #fefefe));
    background: var(--charts-base-04, #01c0b0);
  }
  &.smartcash {
    color: var(--primary-700, #021033);
    background: var(--charts-base-11, #fef600);
  }
`;

export { Tag };
