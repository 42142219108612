import {
  Title,
  BoxHeader,
  ControlBox,
  Container,
  Table,
  TableRowHeader,
  TableHeader,
  TableRow,
  TableCell,
  StatusCircle,
  ActionFilterBox,
  BoxInputDescription,
  BoxInput,
  BoxInfoInput,
  ButtonsBox,
  FilterBox,
  DatePickerWrapper,
  StyledDatePicker,
  CalendarIcon,
} from 'components/ApplicationBusinessList/ApplicationBusinessList.style';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InputSearch } from 'components/common/Input/InputSearch';
import { Pagination } from 'components/Pagination';
import { default as IconRedTimer } from 'assets/img/IconRedTimer.svg';
import { default as IconBlackTimer } from 'assets/img/IconBlackTimer.svg';
import { Checkbox } from 'components/common/Checkbox';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { SuggestionsInterface } from './SuggestionsInvestorList.interface';
import { InputNew } from 'components/common/Input/InputNew/InputNew';
import SelectBox from 'components/common/SelectBox/SelectBox';
import { default as CalendarIcons } from 'assets/img/CalendarIcon.svg';
import { NewButton } from 'components/common/Button';
import { default as IconTune } from 'assets/img/IconTune.svg';

interface PageChangeEventArgs {
  selected: number;
}

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/`;

const SuggestionsInvestorList = () => {
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState<SuggestionsInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationInfo, setPaginationInfo] = useState({
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
  });

  const [isFilterBoxOpen, setFilterBoxOpen] = useState(false);

  // Filter state
  const [requestNum, setRequestNum] = useState<string>('');
  const [minSum, setMinSum] = useState<number | null>(null);
  const [maxSum, setMaxSum] = useState<number | null>(null);
  const [minOfferTransfer, setMinOfferTransfer] = useState<number | null>(null);
  const [maxOfferTransfer, setMaxOfferTransfer] = useState<number | null>(null);
  const [minOfferPenaltyRate, setMinOfferPenaltyRate] = useState<number | null>(null);
  const [maxOfferPenaltyRate, setMaxOfferPenaltyRate] = useState<number | null>(null);
  const [minOfferRate, setMinOfferRate] = useState<number | null>(null);
  const [maxOfferRate, setMaxOfferRate] = useState<number | null>(null);
  const [dateCreateStart, setDateCreateStart] = useState<Date | null>(null);
  const [dateCreateEnd, setDateCreateEnd] = useState<Date | null>(null);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [requestDtEndStart, setRequestDtEndStart] = useState<Date | null>(null);
  const [requestDtEndEnd, setRequestDtEndEnd] = useState<Date | null>(null);
  const [offerExtra, setOfferExtra] = useState<boolean | null>(null);

  const [sortConfig, setSortConfig] = useState<{ field: string; direction: 'asc' | 'desc' | null }>(
    {
      field: '',
      direction: null,
    },
  );

  const navigate = useNavigate();

  const transformedData = (data: any[]): SuggestionsInterface[] => {
    return data.map((item) => ({
      id: item.id,
      request: item.request,
      requestNum: item.requestNum,
      requestDtEnd: item.requestDtEnd,
      offerSum: item.offerSum.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      offerTransfer: item.offerTransfer,
      offerPeriod: item.offerPeriod,
      offerRate: item.offerRate.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      offerPenaltyRate: item.offerPenaltyRate.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      extraInfo: item.extraInfo,
      statusName: item.statusName || 'Не указан',
      overpaymentSum: item.overpaymentSum.toLocaleString('ru-KZ', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      offerExtra: item.offerExtra || '',
      status: item.status || null,
      requestInfo: item.requestInfo || null,
    }));
  };

  const getFilters = () => {
    return {
      requestNum: requestNum || undefined,
      minSum: minSum || undefined,
      maxSum: maxSum || undefined,
      minOfferTransfer: minOfferTransfer || undefined,
      maxOfferTransfer: maxOfferTransfer || undefined,
      minOfferPenaltyRate: minOfferPenaltyRate || undefined,
      maxOfferPenaltyRate: maxOfferPenaltyRate || undefined,
      minOfferRate: minOfferRate || undefined,
      maxOfferRate: maxOfferRate || undefined,
      dateCreateStart: dateCreateStart?.toISOString().split('T')[0] || undefined,
      dateCreateEnd: dateCreateEnd?.toISOString().split('T')[0] || undefined,
      statuses: statuses.length > 0 ? statuses : undefined,
      requestDtEndStart: requestDtEndStart?.toISOString().split('T')[0] || undefined,
      requestDtEndEnd: requestDtEndEnd?.toISOString().split('T')[0] || undefined,
      offerExtra: offerExtra !== null ? offerExtra : undefined,
    };
  };

  const fetchData = useCallback(
    async (
      page = 0,
      filters = getFilters(),
      sort: { field: string; direction: 'asc' | 'desc' | null } = sortConfig,
    ) => {
      if (!BASE_URL) return; // Проверка на наличие базового URL

      try {
        const params = new URLSearchParams();
        params.append('page', page.toString());

        // Добавление сортировки
        if (sort.field && sort.direction) {
          params.append('sort', `${sort.field},${sort.direction}`);
        }

        // Используем POST запрос для отправки фильтров и сортировки
        const response = await axios.post(
          `${BASE_URL}api/v1/cabinet/investor/request/offer/search?${params.toString()}`,
          filters,
          {
            withCredentials: true,
          },
        );

        setTableData(transformedData(response.data.content));
        setPaginationInfo({
          totalPages: response.data.totalPages,
          totalElements: response.data.totalElements,
          currentPage: response.data.number,
        });
      } catch (error) {
        console.error('Ошибка загрузки данных:', error);
      }
    },
    [sortConfig], // Зависимости: BASE_URL и sortConfig
  );

  // Вызов fetchData в useEffect
  useEffect(() => {
    fetchData(); // Передаем начальную страницу и сортировку
  }, [fetchData]); // Зависимость: функция fetchData

  const handlePageChange = ({ selected }: PageChangeEventArgs) => {
    fetchData(selected);
    setCurrentPage(selected);
  };

  const handleSort = (field: string) => {
    setSortConfig((prevSortConfig) => {
      if (prevSortConfig.field === field) {
        return {
          field,
          direction: prevSortConfig.direction === 'asc' ? 'desc' : 'asc',
        };
      }
      return { field, direction: 'asc' };
    });
  };

  const applyFiltersAndSorting = () => {
    setCurrentPage(0); // Reset to page 0 when applying filters
    fetchData(0, getFilters(), sortConfig);
  };

  const clearFilters = () => {
    setRequestNum('');
    setMinSum(null);
    setMaxSum(null);
    setMinOfferTransfer(null);
    setMaxOfferTransfer(null);
    setMinOfferPenaltyRate(null);
    setMaxOfferPenaltyRate(null);
    setMinOfferRate(null);
    setMaxOfferRate(null);
    setDateCreateStart(null);
    setDateCreateEnd(null);
    setStatuses([]);
    setRequestDtEndStart(null);
    setRequestDtEndEnd(null);
    setOfferExtra(null);

    fetchData(0);
  };

  const filteredData = useMemo(() => {
    if (searchValue) {
      return tableData.filter((row) => row.requestNum.toString().includes(searchValue));
    }
    return tableData;
  }, [searchValue, tableData]);

  const calculateRemainingTime = (endDateString: string) => {
    const endDate = new Date(endDateString);
    const now = new Date();
    const timeDiff = endDate.getTime() - now.getTime();

    if (timeDiff <= 0) {
      return { text: 'Время истекло', expired: true };
    }

    const daysRemaining = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysRemaining >= 1) {
      return { text: `${daysRemaining} ${daysRemaining === 1 ? 'день' : 'д'}`, expired: false };
    }

    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60)) / (1000 * 60));

    return { text: `${hours}:${minutes}`, expired: false };
  };

  const toggleFilterBox = () => {
    setFilterBoxOpen((prev) => !prev);
  };

  return (
    <Container>
      <Title>Мои предложения</Title>
      <BoxHeader>
        <ControlBox>
          <InputSearch
            value={requestNum}
            onChange={(e) => setRequestNum(e.target.value)}
            placeholder='Введите номер сделки'
            name='searchQuery'
          />
          <FilterBox onClick={toggleFilterBox} isActive={isFilterBoxOpen}>
            <img src={IconTune} alt='Фильтр' />
          </FilterBox>
        </ControlBox>
      </BoxHeader>
      {isFilterBoxOpen && (
        <ActionFilterBox>
          <BoxInputDescription>
            <BoxInput>
              <p>Сумма займа</p>
              <BoxInfoInput>
                <span>От</span>
                <InputNew
                  placeholder='0'
                  value={minSum !== null ? minSum.toString() : ''}
                  onChange={(e) => setMinSum(e.target.value ? parseFloat(e.target.value) : null)}
                  maxWidth='100px'
                  type='number'
                />
                <span>До</span>
                <InputNew
                  placeholder='0'
                  value={maxSum !== null ? maxSum.toString() : ''}
                  onChange={(e) => setMaxSum(e.target.value ? parseFloat(e.target.value) : null)}
                  maxWidth='100px'
                  type='number'
                />
              </BoxInfoInput>
            </BoxInput>
            <BoxInput>
              <p>Начало финансирования</p>
              <BoxInfoInput>
                <span>От</span>
                <InputNew
                  placeholder='0'
                  value={minOfferTransfer !== null ? minOfferTransfer.toString() : ''}
                  onChange={(e) =>
                    setMinOfferTransfer(e.target.value ? parseFloat(e.target.value) : null)
                  }
                  maxWidth='100px'
                  type='number'
                />
                <span>До</span>
                <InputNew
                  placeholder='0'
                  value={maxOfferTransfer !== null ? maxOfferTransfer.toString() : ''}
                  onChange={(e) =>
                    setMaxOfferTransfer(e.target.value ? parseFloat(e.target.value) : null)
                  }
                  maxWidth='100px'
                  type='number'
                />
              </BoxInfoInput>
            </BoxInput>
            <BoxInput>
              <p>Пеня за день</p>
              <BoxInfoInput>
                <span>От</span>
                <InputNew
                  placeholder='0'
                  value={minOfferPenaltyRate !== null ? minOfferPenaltyRate.toString() : ''}
                  onChange={(e) =>
                    setMinOfferPenaltyRate(e.target.value ? parseFloat(e.target.value) : null)
                  }
                  maxWidth='100px'
                  type='number'
                />
                <span>До</span>
                <InputNew
                  placeholder='0'
                  value={maxOfferPenaltyRate !== null ? maxOfferPenaltyRate.toString() : ''}
                  onChange={(e) =>
                    setMaxOfferPenaltyRate(e.target.value ? parseFloat(e.target.value) : null)
                  }
                  maxWidth='100px'
                  type='number'
                />
              </BoxInfoInput>
            </BoxInput>
          </BoxInputDescription>
          <BoxInputDescription>
            <BoxInput>
              <p>Месячная ставка </p>
              <BoxInfoInput>
                <span>От</span>
                <InputNew
                  placeholder='0'
                  value={minOfferRate !== null ? minOfferRate.toString() : ''}
                  onChange={(e) =>
                    setMinOfferRate(e.target.value ? parseFloat(e.target.value) : null)
                  }
                  maxWidth='100px'
                  type='number'
                />
                <span>До</span>
                <InputNew
                  placeholder='0'
                  value={maxOfferRate !== null ? maxOfferRate.toString() : ''}
                  onChange={(e) =>
                    setMaxOfferRate(e.target.value ? parseFloat(e.target.value) : null)
                  }
                  maxWidth='100px'
                  type='number'
                />
              </BoxInfoInput>
            </BoxInput>
            <BoxInput>
              <p>Дата предложения</p>
              <BoxInfoInput>
                <span>С</span>
                <DatePickerWrapper>
                  <StyledDatePicker
                    selected={dateCreateStart}
                    dateFormat='dd/MM/yyyy'
                    onChange={(date: Date | null) => setDateCreateStart(date)}
                    placeholderText='Выберите дату'
                  />
                  <CalendarIcon src={CalendarIcons} alt='Календарь' />
                </DatePickerWrapper>
                <span>По</span>
                <DatePickerWrapper>
                  <StyledDatePicker
                    dateFormat='dd/MM/yyyy'
                    selected={dateCreateEnd}
                    onChange={(date: Date | null) => setDateCreateEnd(date)}
                    placeholderText='Выберите дату'
                  />
                  <CalendarIcon src={CalendarIcons} alt='Календарь' />
                </DatePickerWrapper>
              </BoxInfoInput>
            </BoxInput>
            <BoxInput>
              <p>Срок завершения</p>
              <BoxInfoInput>
                <span>С</span>
                <DatePickerWrapper>
                  <StyledDatePicker
                    selected={requestDtEndStart}
                    dateFormat='dd/MM/yyyy'
                    onChange={(date: Date | null) => setRequestDtEndStart(date)}
                    placeholderText='Выберите дату'
                  />
                  <CalendarIcon src={CalendarIcons} alt='Календарь' />
                </DatePickerWrapper>
                <span>По</span>
                <DatePickerWrapper>
                  <StyledDatePicker
                    dateFormat='dd/MM/yyyy'
                    selected={requestDtEndEnd}
                    onChange={(date: Date | null) => setRequestDtEndEnd(date)}
                    placeholderText='Выберите дату'
                  />
                  <CalendarIcon src={CalendarIcons} alt='Календарь' />
                </DatePickerWrapper>
              </BoxInfoInput>
            </BoxInput>
          </BoxInputDescription>
          <ButtonsBox>
            <NewButton text='Сбросить все фильтры' className='white small' onClick={clearFilters} />
            <NewButton
              text='Применить фильтры'
              className='small'
              onClick={applyFiltersAndSorting}
            />
          </ButtonsBox>
        </ActionFilterBox>
      )}
      <Table>
        <thead>
          <TableRowHeader>
            <TableHeader
              isSorted={sortConfig.field === 'requestNum'}
              direction={sortConfig.field === 'requestNum' ? sortConfig.direction : null}
              onClick={() => handleSort('requestNum')}>
              Номер сделки
            </TableHeader>
            <TableHeader
              isSorted={sortConfig.field === 'offerSum'}
              direction={sortConfig.field === 'offerSum' ? sortConfig.direction : null}
              onClick={() => handleSort('offerSum')}>
              Cумма займа
            </TableHeader>
            <TableHeader
              isSorted={sortConfig.field === 'offerPeriod'}
              direction={sortConfig.field === 'offerPeriod' ? sortConfig.direction : null}
              onClick={() => handleSort('offerPeriod')}>
              Срок финансирования
            </TableHeader>
            <TableHeader
              isSorted={sortConfig.field === 'offerTransfer'}
              direction={sortConfig.field === 'offerTransfer' ? sortConfig.direction : null}
              onClick={() => handleSort('offerTransfer')}>
              Начало финансирования
            </TableHeader>
            <TableHeader
              isSorted={sortConfig.field === 'offerRate'}
              direction={sortConfig.field === 'offerRate' ? sortConfig.direction : null}
              onClick={() => handleSort('offerRate')}>
              Месячная ставка
            </TableHeader>
            <TableHeader
              isSorted={sortConfig.field === 'offerPenaltyRate'}
              direction={sortConfig.field === 'offerPenaltyRate' ? sortConfig.direction : null}
              onClick={() => handleSort('offerPenaltyRate')}>
              Пеня за день
            </TableHeader>
            <TableHeader>Доход</TableHeader>
            <TableHeader
              isSorted={sortConfig.field === 'requestEnd'}
              direction={sortConfig.field === 'requestEnd' ? sortConfig.direction : null}
              onClick={() => handleSort('requestEnd')}>
              Срок завершения
            </TableHeader>

            <TableHeader>Статус предложения</TableHeader>
          </TableRowHeader>
        </thead>
        <tbody>
          {filteredData.map((row) => {
            const { text: remainingTime, expired } = calculateRemainingTime(row.requestDtEnd);

            return (
              <TableRow key={row.id} onClick={() => navigate(`/suggestions/${row.id}`)}>
                <TableCell>
                  <Link
                    style={{ color: '#5784F7', textDecoration: 'none' }}
                    to={`/request/${row.request}`}>
                    {row.requestNum}
                  </Link>
                </TableCell>
                <TableCell className='right'>{row.offerSum} ₸</TableCell>
                <TableCell className='right'>{row.offerPeriod}</TableCell>
                <TableCell className='right'>{row.offerTransfer}</TableCell>
                <TableCell className='right'>{row.offerRate} %</TableCell>
                <TableCell className='right'>{row.offerPenaltyRate} %</TableCell>
                <TableCell className='right'>{row.overpaymentSum} ₸</TableCell>
                <TableCell style={{ color: expired ? 'red' : 'inherit' }}>
                  <div>
                    <img src={expired ? IconRedTimer : IconBlackTimer} alt='Timer Icon' />
                    {remainingTime}
                  </div>
                </TableCell>
                <TableCell>
                  <StatusCircle $status={row.statusName || ''} />
                  {row.statusName}
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
      <Pagination
        pageCount={Math.max(paginationInfo.totalPages, 1)}
        currentPage={Math.min(currentPage, paginationInfo.totalPages - 1)}
        onPageChange={handlePageChange}
      />
    </Container>
  );
};

export default SuggestionsInvestorList;
